import React from 'react'
import Layout from '../components/App/Layout';
import SEO from '../components/App/SEO';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import { graphql, StaticQuery } from 'gatsby';
import BlogCard from '../components/BlogContent/BlogCard';

const BlogPage = ({location}) => (
    
    <StaticQuery
        query={graphql`
        query BlogIndexQuery {
            allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "blog" } }}
            ) {
                edges {
                    node {
                        id
                        html
                        excerpt
                        frontmatter {
                            publishToProd
                            templateKey
                            path
                            title
                            category
                            metaTitle
                            date
                            image
                            blogTitle
                            description
                            metaDescription
                        }
                    }
                }
            }
            site {
                siteMetadata {
                  title
                  env
                  imagesPrefix
                }
            }
        }
        `}
        render={data => (
            <Layout location={location} env={data.site.siteMetadata.env}>
                <Navbar />
                <SEO 
                    postTitle='Blog | BlueMail App' 
                    postDescription='BlueMail Blog'
                    postImage='/img/OG/og_image_Blog.png'
                    postURL='blog'
                    postSEO
                />
                <div className="post-feed-container bg-nav">
                    <BlogCard data={data.allMarkdownRemark.edges.sort((a , b) =>  new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date))} />
                </div>
                <Footer />
            </Layout>
        )}
    />
)

export default BlogPage

